<template>
    <transition name="fade">
        <div>
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {{ modal_title }}
                            </h5>
                            <button type="button" class="btn-close" @click="close"></button>
                        </div>
                        <div class="modal-body pt-2">
                            <template v-for="employee_absence in employee_absences" :key="employee_absence">
                                <div class="row align-items-center border-bottom py-2">
                                    <div class="mb-3 fw-bold">
                                        {{ employee_absence.employee.photographer_name }}
                                    </div>
                                    <div class="col-8">
                                        <form-checkbox
                                            v-model="employee_absence.is_absent"
                                            option_label="休み"
                                        ></form-checkbox>
                                    </div>
                                    <div class="col-8">
                                        <form-select
                                            v-if="parseInt(employee_absence.is_absent, 10) === IsAbsent.YES"
                                            v-model="employee_absence.absence_type"
                                            :options="absence_types"
                                        ></form-select>
                                    </div>
                                    <div class="col-8">
                                        <form-select
                                            v-if="parseInt(employee_absence.is_absent, 10) === IsAbsent.YES"
                                            v-model="employee_absence.absence_time"
                                            :options="absence_times"
                                        ></form-select>
                                    </div>
                                    <div class="mb-2 mt-3" v-if="employee_absence.is_absent">
                                        <form-input
                                            v-model="employee_absence.absence_memo"
                                            placeholder="備考の追加"
                                        ></form-input>
                                    </div>
                                    <div class="mt-3 text-start text-muted" style="font-size: 0.9rem !important">
                                        {{ employee_absence.schedule_names }}
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="modal-footer">
                            <button-exec-update
                                @click="save"
                            >
                            </button-exec-update>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import FormCheckbox from '@/components/forms/FormCheckbox';
import FormSelect from '@/components/forms/FormSelect';
import FormInput from '@/components/forms/FormInput';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import Absence from '@/models/entities/absence';
import IsAbsent from '@/models/enums/schedule/is-absent';
import AbsenceType from '@/models/enums/schedule/absence-type';
import AbsenceTime from '@/models/enums/schedule/absence-time';

export default {
    name: 'AbsenceModal',
    components: {
        FormCheckbox,
        FormSelect,
        FormInput,
        ButtonExecUpdate
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    props: {
        modelValue: {
            type: Object
        },
        employees: {
            type: Array,
            default: () => ([])
        },
        schedules: {
            type: Array,
            default: () => ([])
        },
        date: {
            type: String,
        },
    },
    emits: [
        'update:modelValue',
        'close',
        'update'
    ],
    data() {
        return {
            absences: [],
            employee_absences: [],

            IsAbsent: IsAbsent,

            absence_types: AbsenceType.options(),
            absence_times: AbsenceTime.options(),

        }
    },
    mounted() {
        this.setEmployeeAbsences();

    },
    watch: {

    },
    computed: {
        modal_title() {
            let date = this.$helper.nj(this.date);
            let absence_count = this.employee_absences.filter(employee_absence => employee_absence.is_absent === IsAbsent.YES).length;

            return `${date} 休み（${absence_count}名）`;
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        // スケジュール名抽出
        extractScheduleName(employee) {
            let schedule_names = '予定なし';

            let schedule_names_array = [];
            for (let schedule of this.schedules) {
                if (
                    schedule.photographers.some(schedule_photographer => schedule_photographer.photographer.photographer_id === employee.photographer_id)
                ) {
                    schedule_names_array.push(schedule.schedule_name_display);
                }
            }

            if (schedule_names_array.length) {
                schedule_names = schedule_names_array.join(', ');
            }

            return schedule_names;
        },
        setEmployeeAbsences() {
            this.absences = this.modelValue;

            for (let employee of this.employees) {
                let employee_absence = {
                    is_absent: null,
                    employee: null,
                    absence_type: null,
                    absence_time: null,
                    absence_memo: null,
                    schedule_names: this.extractScheduleName(employee),
                }

                let index = this.absences.findIndex(absence => parseInt(absence.employee.photographer_id, 10) === parseInt(employee.photographer_id, 10));

                if (index > -1) {
                    employee_absence.is_absent = IsAbsent.YES;
                    employee_absence.employee = this.absences[index].employee;
                    employee_absence.absence_type = this.absences[index].absence_type;
                    employee_absence.absence_time = this.absences[index].absence_time;
                    employee_absence.absence_memo = this.absences[index].absence_memo;

                } else {
                    employee_absence.is_absent = IsAbsent.NO;
                    employee_absence.employee = employee;
                    employee_absence.absence_type = AbsenceType.DAY_OFF;
                    employee_absence.absence_time = AbsenceTime.ALL_DAY;
                    employee_absence.absence_memo = null;
                }

                this.employee_absences.push(employee_absence);
            }
        },
        writeAbsence() {
            this.absences.splice(0);

            for (let employee_absence of this.employee_absences) {
                if (employee_absence.is_absent === IsAbsent.YES) {
                    let absence = new Absence();
                    absence.employee = employee_absence.employee;
                    absence.absence_date = this.date;
                    absence.absence_type = employee_absence.absence_type;
                    absence.absence_time = employee_absence.absence_time;
                    absence.absence_memo = employee_absence.absence_memo;

                    this.absences.push(absence);
                }
            }
        },
        save() {
            this.startScreenLoading();

            this.writeAbsence();

            this.$http.put(`/absences`, {
                date: this.date,
                absences: this.absences
            })
            .then(() => {
                this.showMessage('保存しました');
                this.$emit('update:modelValue', this.absences);
                this.$emit('update');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
